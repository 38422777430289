/**
TODO:
1. Consolidate right-aligned and center-aligned selectors
**/

/* Using border box to prevent contents from expanding outside their containers */
/* https://css-tricks.com/box-sizing/ */

@media screen {
  .applicationHeader {
    font-size: var(--font-size-mobile-default);
  }
  .app-body {
    font-size: var(--font-size-mobile-default);
  }
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: var(--background);
  font-family: Poppins-Regular, Arial, sans-serif;
  color: var(--text);
}

.testBadge {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 1em;
  margin-bottom: 1em;
  padding: 0.25em;
  background-color: var(--mexican-red);
  border-radius: 0.25em;
}
.testBadge:hover {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.overlay.progress:hover {
  cursor: not-allowed;
}

.cursorPointer {
  cursor: pointer;
}

/* https://stackoverflow.com/questions/6080413/input-doesnt-inherit-the-font-from-body */
input,
select,
textarea,
button {
  font-size: inherit;
  font-family: inherit;
}

input[type="file"] {
  display: none;
}

input::-moz-selection {
  background-color: var(--blue-button);
  color: var(--white);
}

input::selection {
  background-color: var(--blue-button);
  color: var(--white);
}

input::-webkit-selection {
  background-color: var(--blue-button);
  color: var(--white);
}

a[href^="tel:"] {
  color: var(--text);
  text-decoration: none;
}

h1 {
  margin-top: 0;
}
img.errorPup {
  margin-top: 2.5em;
}
.centerAligned {
  text-align: center;
}
.right-aligned {
  text-align: right;
}
.monospace {
  font-family: monospace;
}
.gridCenter {
  margin: auto;
}
.svgGridCenter svg {
  margin: auto;
}
.maxWidth20 {
  max-width: 20em;
}
.calendarWidget {
  background-color: var(--container);
}

.calendarWidget .month,
.calendarWidget .blank,
.calendarWidget .day {
  width: 100%;
  border-top: 0.15em solid var(--defaultBorderColor);
  border-left: 0.15em solid var(--defaultBorderColor);
}
.calendarWidget .day {
  min-height: 10em;
}
.calendarWidget .right {
  border-right: 0.1em solid var(--defaultBorderColor);
}
.calendarWidget .trailing {
  border-bottom: 0.1em solid var(--defaultBorderColor);
}
.calendarWidget .month {
  height: 2em;
  background-color: var(--monitoringBackgroundHeaders);
  border-radius: var(--border-radius) var(--border-radius) 0 0;

  color: var(--container);
  font-size: var(--font-size-desktop-header);
  font-weight: bold;
  text-align: center;
  padding: 0.25em;
  border-right: 0.1em solid var(--defaultBorderColor);
  grid-column: span 7;
}
.calendarWidget div .month span {
  margin: auto;
  grid-column: span 7;
}
.dayOfMonth {
  font-weight: bold;
  color: black;
  background-color: #ead8c3;
}
.dayLabel {
  padding: 0 0.5em;
  border-right: 0.1em solid var(--defaultBorderColor);
  border-bottom: 0.1em solid var(--defaultBorderColor);
}
.event {
  margin: 0.8em 0.5em;
  color: var(--highlight);
}
.monitoringWrapper {
  margin: 1em;
  padding: 5%;
  background-color: var(--body);
  color: var(--monitoringBackgroundHeaders);
  border-radius: var(--border-radius);
}

.monitoringHeader select {
  color: var(--monitoringBackgroundHeaders);
  background-color: var(--input-background);
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  border-radius: var(--border-radius);
  padding: 0.5em;
  margin-bottom: 0.5em;
}

.metric {
  background-color: var(--container);
  border-radius: var(--border-radius);
  min-height: 15em;
  margin-bottom: 1em;
  padding: 1em 1.5em 1em 1em;
  border-top-width: 0.75em;
  border-top-style: solid;
  border-top-color: var(--monitoringBackgroundHeaders);
  width: 15em;
  flex-grow: 5;
  flex-basis: auto;
}

.overflowHidden {
  overflow: hidden;
}
.importWarning {
  background-color: var(--container);
  padding: 0.25em;
  border-left: 2px solid var(--highlight);
  border-right: 2px solid var(--highlight);
}
.progessBorder {
  background-color: var(--container);
}

/* color before event begins */
.progessBar {
  background-color: var(--progressBar);
  height: 1.5em;
  text-align: right;
  color: var(--progressText);
}
/* color during event */
.progressSpinner {
  color: var(--progressSpinner);
}

.progressContainer {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1em;
  row-gap: 0.5em;
  align-items: center;
}
.loadOlderMessages {
  align-self: center;
  padding: 0.25em;
  color: var(--highlight);
  font-style: italic;
  font-size: smaller;
}

.createAccount {
  margin: 1em 0;
  color: var(--headerText);
}
.createAccount a {
  color: var(--headerText);
  font-style: italic;
  text-decoration: none;
}
.navLink {
  margin-bottom: 0.25em;
  padding: 0.25em;
  text-decoration: none;
  color: var(--navLink);
  font-style: italic;
  font-weight: bold;
  border-radius: var(--border-radius);
}

.navLink:hover {
  color: var(--navLinkHover);
  background-color: var(--blue-button);
  border-radius: var(--border-radius);
}

.inlineLink {
  text-decoration: none;
  color: var(--highlight);
  font-style: italic;
}
.inlineLink:hover {
  color: var(--highlightHover);
}

.marginTop2em {
  margin-top: 2em;
}
.marginTop1em {
  margin-top: 1em;
}
.marginTophalfem {
  margin-top: 0.5em;
}
.marginBottom1em,
.merchantDisabled {
  margin-bottom: 1em;
}
.marginBottomhalfem {
  margin-bottom: 0.5em;
}
.marginBottomqtrem {
  margin-bottom: 0.25em;
}
.marginRight1em {
  margin-right: 1em;
}

.paddingqtrem {
  padding: 0.25em;
}
.paddingHalfEm {
  padding: 0.5em;
}
.paddingLefthalfem {
  padding-left: 0.5em;
}
.paddingLeftOneEm {
  padding-left: 1em;
}
.paddingRight1em {
  padding-right: 10em;
}

.minHeight2em {
  min-height: 2em;
}
.minHeight3em {
  min-height: 3em;
}

.noBorder {
  border: none;
  outline: none;
}
.areaOrderNumber .tagContainer,
.areaProductHeader .tagContainer,
.areaContactHeader .tagContainer {
  padding: 0;
  margin: 0 0 1em 0;
  font-size: 50%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1em;
}
.areaOrderNumber .tagContainer {
  justify-content: flex-end;
}
.tagGrid.orderTypeTag {
  margin-bottom: 0.45em;
}

.tagElementContainer {
  display: flex;
  flex-wrap: wrap;
  /* column-gap: 1em; */
  row-gap: 0.25em;
}

.tag {
  background-color: var(--container);
  border-radius: var(--border-radius);
  padding: 0 0.4em;
  font-weight: normal;
  border-width: 0.1em;
  border-style: solid;
  border-color: var(--blue-button);
  border-left-width: 0.5em;
  display: flex;
  column-gap: 0.5em;
  margin-left: 0.5em;
}
.tag.editing {
  max-height: 3.5em;
  font-size: 200%;
}
.tagEditControls {
  display: grid;
  grid-template-columns: 2.5em max-content max-content;
  column-gap: 0.5em;
}
.tagEditControls span {
  margin: auto;
}
.tagText {
  min-width: 6em;
}
.tagList input[type="color"] {
  height: 100%;
}
.tagDelete {
  font-size: 80%;
  margin: auto;
}
.tagAdd svg {
  transform: rotate(90deg);
}

.tooltip.sourceorder .tooltiptext {
  width: revert;
  font-size: 50%;
  white-space: nowrap;
  padding: 0.5em;
}

.explanation {
  width: 20em;
  border: 0.1em solid var(--text);
  background-color: var(--container);
  padding: 0.5em;
  border-radius: var(--border-radius);
}
.explanationNarrow {
  width: max-content;
}
.cartloomPID {
  transform: translate(-50%, 75%);
}

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  background-color: var(--search-background);
  border: 0.1em solid var(--defaultBorderColor);
  color: var(--text);
  text-align: center;
  border-radius: var(--border-radius);
  padding: 0.25em 0;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  left: 50%;
  margin-left: -3em;
  opacity: 0;
  transition: opacity 0.3s;
  transform: translate(-50%, 100%);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: -0.55em;
  left: 50%;
  margin-left: -0.25em;
  border-width: 0.25em;
  border-style: solid;
  border-color: transparent transparent var(--defaultBorderColor) transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.dbwDemo,
.dbwNews {
  overflow: hidden;
  position: relative;
}
/* NOTE: Max height for the NewsWidget (dbwNews) and DemoWidget (dbwDemo) are being controlled by react-spring in the JSX file */
.dbwDemo .dbwExpander,
.dbwNews .dbwExpander {
  position: absolute;
  bottom: 0.25em;
  right: 0.5em;
  color: var(--headerText);
}
.dbwExpander:hover {
  color: var(--highlight);
}

#list-container .tagListIcon {
  opacity: 0.25;
}
#list-container .tagListIcon:hover {
  opacity: 1;
}

.areaTransactions .action-button {
  padding: 0;
}
.addWidget {
  font-size: 140%;
  color: var(--green-button);
  margin: 0 0 0 auto;
}
.addWidget:hover {
  color: var(--green-button-hover);
}
.action-button {
  color: var(--buttonText);
  display: block;
  padding: 0.4em;
  text-align: center;
  border-radius: var(--border-radius);
  cursor: pointer;
  min-width: 6.5em;
  user-select: none;
  font-size: var(--font-size-mobile-button);
}
.action-button-inline {
  display: inline-block;
}
.clockout-button {
  color: var(--buttonText);
  display: block;
  text-align: center;
  border-radius: var(--border-radius);
  cursor: pointer;
  width: max-content;
  height: max-content;
  user-select: none;
  font-size: var(--font-size-mobile-button);
}
.manualCardStatus {
  margin: auto auto 1.5em auto;
  width: max-content;
}
.action-button-cell {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  margin-left: 0.05em;
  margin-right: 0.05em;
}
.action-button:hover {
  cursor: pointer;
}
.action-buttons .action-button svg {
  color: var(--buttonText);
  fill: var(--buttonText);
  height: 1em;
}
.action-buttons .total {
  display: flex;
}
.actionButtonStockOrder {
  color: var(--buttonText);
  padding: 0.3em;
  width: 6em;
  height: auto;
  border-radius: var(--border-radius);
  user-select: none;
}
.actionButtonStockOrder svg {
  width: 2em;
  height: auto;
}
.actionButtonWalkIn {
  color: var(--buttonText);
  padding: 0.3em;
  width: 5em;
  height: auto;
  border-radius: var(--border-radius);
  user-select: none;
}
.planButton {
  font-size: var(--font-size-larger);
  width: 11em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.action-icon-container {
  display: flex;
}
.action-icons {
  padding-top: 0.6em;
  padding-left: 0.6em;
}
.areaCustomerSearchContainer .action-icons {
  padding: 0;
}
.areaProductSearchContainer .action-icons {
  padding-top: 1em;
  padding-left: 0;
}
.areaBillingPlanSearchContainer {
  padding-top: 1em;
}
.areaStoreCredit {
  color: var(--highlight);
  font-weight: bold;
  margin: auto;
}

.action-icons.add-contact {
  margin-left: auto;
}
.action-icons.expand-contacts {
  margin-right: auto;
}
.action-row-two,
.action-row-three,
.action-row-four {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 0.25em;
}
.action-tab {
  color: var(--tabText);
  background-color: var(--tab);
  display: inline-block;
  text-align: center;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  user-select: none;
  margin: 1em 0.5em 0 0;
  padding: 0.5em 0.75em;
  min-width: 3em;
}

.action-tab-balance {
  font-size: var(--font-size-larger);
}
.action-tab-balance span {
  margin: 0.75em 0 auto auto;
}
.action-tab.selected {
  background-color: var(--tabSelected);
  font-weight: bold;
}
.photoListItem {
  position: relative;
}
.photoListItem li div {
  display: inline-block;
}
.photoListItem.placeholder.dragOver {
  background-color: var(--highlight);
  opacity: 0.4;
  transition: 0.25s;
}
.photoListItem.placeholder {
  fill: var(--placeholderText);
  color: var(--placeholderText);
  flex-grow: 1;
  border: 2px dashed var(--defaultBorderColor);
  display: grid;
  opacity: 0.4;
}

.generalSettings .photoListItem.placeholder {
  height: 200px;
}

.photoListItem.placeholder svg {
  margin: auto;
}
.photoListItem.deleting {
  opacity: 0;
  transition: 0.75s;
}
.popup {
  position: relative;
  display: inline-block;
}
.popup .popuplist {
  background-color: var(--search-background);
  border: 0.1em solid var(--defaultPopupColor);
  color: var(--text);
  border-radius: var(--border-radius);
  padding: 1em;
  position: absolute;
  z-index: 1;
}
.popup .popuplist::after {
  content: "";
  position: absolute;
  top: -0.55em;
  left: 9%;
  margin-left: -0.25em;
  border-width: 0.25em;
  border-style: solid;
  border-color: transparent transparent var(--defaultBorderColor) transparent;
}
.popup .popuplist .list-item {
  padding-left: 0;
  padding-right: 0;
}
.popup .statusbadge {
  padding: 0;
  width: 10em;
}

.popup .statusbadge.family {
  padding: 0.25em 0;
  width: 100%;
}
.addr1 {
  padding-top: 1em;
}
.app-body {
  background-color: var(--background);
}
.areaAddProductContainer {
  margin-top: 1em;
}

.areaBaseDetailContainer {
  margin: 1em;
  padding: 5%;
  background-color: var(--body);
  color: var(--text);
  border-radius: var(--border-radius);
}

.areaButtonControlsContainer {
  margin-top: 1em;
}
.areaCloseButton {
  text-align: right;
}
.areaCloseButton svg {
  font-size: var(--fontSizeHeadingSmall);
}

.tagList input,
.areaContactHeader input {
  color: var(--text);
  background-color: var(--container);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  width: 100%;
  border: none;
  outline-width: 0;
  outline: none;
  font-weight: bold;
  padding-left: 0.5em;
}
.areaFoldersContainer {
  margin: 3em 0 2em 0;
}

.areaOrderTotalContainer {
  margin-top: 1em;
}
.areaPaymentList .orderpaymentlist {
  margin-top: 1em;
}
.areaCampaignHeader,
.areaReportHeader,
.areaProductHeader,
.areaContactHeader {
  font-size: var(--fontSizeHeading);
  margin-bottom: 0.5em;
  font-weight: bold;
}

/* prevent bounce during search */
.areaDetailPurchase .areaContactHeader,
.areaDetailQuote .areaContactHeader,
.areaDetailRepair .areaContactHeader,
.areaDetailOrder .areaContactHeader,
.areaDetailInvoice .areaContactHeader {
  min-height: 1.45em;
}
.areaDetailSupplier .areaContactHeader {
  height: auto;
}
.areaDetailRecurring .areaContactHeader,
.areaDetailBilling .areaContactHeader {
  font-size: var(--fontSizeHeadingMedium);
}

.areaOrderNumber {
  font-size: var(--fontSizeHeading);
  font-weight: bold;
}

.areaProspectStatus,
.areaOrderStatus {
  display: grid;
}
.areaProspectStatus span,
.areaOrderStatus span {
  margin: 0 0 0 auto;
}
.areaProspectStatus .statusbadge,
.areaOrderStatus .statusbadge {
  margin: 0 0 0.75em auto;
}

.reportButton {
  margin: 1em;
}
.dateFilterType,
.reportFilterType {
  margin: auto auto auto 0;
}

.areaCustomerSearchContainer {
  margin: 0;
}
/* .areaCustomerSearchContainer .search-wrapper { margin: 0.5em 0.5em auto 0; } */

.areaProductSearchContainer .search-wrapper {
  margin: 1em 0 auto 0;
}

.areaProductSearchContainer {
  margin: 0 auto 0 0;
}

.areaRepairCardDeck {
  margin-top: 1em;
}
.areaRepairItemCard {
  resize: none;
  border-radius: var(--border-radius);
  margin-bottom: 2em;
}
.areaRepairItemCard input,
.areaRepairItemCard textarea {
  margin: 0.1em;
  width: 98%;
  border-radius: var(--border-radius);
  background-color: var(--input-background);
  border: none;
  outline: none;
  color: var(--text);
  overflow: hidden;
  resize: none;
}
.labelHeader,
.areaRepairItemCard label,
.repairlabel {
  margin-top: 0.1em;
  color: var(--text);
}
.areaRepairItemInputContainer .popup .popuplist {
  top: 1.75em;
  left: 0;
  width: 100%;
}
.areaRepairItemInputContainer {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5em;
}
.areaRepairItemInputContainer input,
.areaRepairItemInputContainer textarea,
.areaRepairItemInputContainer span {
  width: 100%;
  padding: 0.1em 0;
}
.areaRepairItemInputContainer label {
  padding: 0.1em 0;
}
.areaRepairItemInputContainer.description {
  display: inline;
}
.areaCampaignLink {
  text-align: center;
}

.campaignLink {
  margin-bottom: 1em;
}
.areaTrackingNumber {
  width: 17em;
}
.areaPONumber {
  width: 15em;
}
.balanceDue {
  color: var(--status-badge-open);
  text-align: center;
}
.black-button {
  background-color: var(--black-button);
}
.black-button:hover {
  background-color: var(--black-button-hover);
}
.border-radius {
  border-radius: var(--border-radius);
}

.brown-button {
  background-color: var(--brown-button);
}
.brown-button:hover {
  background-color: var(--brown-button-hover);
}
.cancelbutton .payment-action-button,
.processbutton .payment-action-button {
  width: 10em;
  margin-top: 2em;
}
.cardreaderstatus {
  text-align: center;
  font-size: var(--font-size-larger);
  margin: 1.5em 0;
  color: var(--text);
  min-height: 4.5em;
}
.spinner-container {
  position: relative;
  display: inline-block;
}
.spinner-icon {
  font-size: 2em;
}
.countdown-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -58%);
  font-size: 0.75em;
  color: var(--white);
}
.caret {
  padding: 0.19em 0.62em;
}
.circle-button {
  stroke: var(--text);
  fill: var(--search-background);
}
.circle-button svg {
  height: 2em;
  width: 2em;
}
.strikethrough {
  text-decoration: line-through;
}
.cloud-error {
  color: var(--red-button-hover);
  fill: var(--red-button-hover);
}
.leftMarginSmall,
.cloud-icon {
  margin-left: 0.5em;
}
.component-title {
  color: var(--text);
}
.approvedCheck {
  color: var(--green-button);
}
.declinedX {
  color: var(--red-button);
}

.contact-view-top-row {
  padding-bottom: 1em;
}
.contact-view-top-row svg {
  padding-left: 1em;
  margin: auto 0;
  height: 1em;
  opacity: 0.75;
}

.envelopeSlash {
  fill: var(--highlight);
}

.contact-card {
  background-color: var(--container);
  border-radius: var(--border-radius);
  padding: 0.5em;
  width: 100%;
  margin-bottom: 1em;
}
.contact-details {
  border-radius: var(--border-radius);
}
.contact-item {
  padding: 0 0.5em 0.25em 0.5em;
  background-color: var(--container);
  min-width: 16em;
  max-width: 21em;
  overflow: hidden;
}
.contact-item input {
  color: var(--text);
  background-color: var(--container);
  outline-width: 0;
  border: none;
  outline: none;
  width: 100%;
}
/* Safari does not automatically style place holder text */
::-webkit-input-placeholder {
  opacity: 0.75;
}

/* *********************************************** */
/*                  SWITCHES                       */
/* *********************************************** */
/* Contact Card  Switches: Do not email and Active */
.contactDonotemailSwitch .slider,
.contact-active-switch .slider {
  padding-left: 0;
}

/* Switch width are impacted when the labels changes    */
.contactDonotemailSwitch .switch {
  width: 8em;
}
.contact-active-switch .switch {
  width: 6em;
}

/* Switch transform must also changes when labels change */
.contactDonotemailSwitch .switch input:checked + .slider:before {
  transform: translateX(6.2em);
}
.contact-active-switch .switch input:checked + .slider:before {
  transform: translateX(4.1em);
}

.contactDonotemailSwitch input + .slider > .no,
.contact-active-switch input + .slider > .no {
  display: inline;
  margin-left: 1.5em;
}
.contactDonotemailSwitch input + .slider > .yes,
.contact-active-switch input + .slider > .yes,
.contactDonotemailSwitch input:checked + .slider > .no,
.contact-active-switch input:checked + .slider > .no {
  display: none;
}
.contactDonotemailSwitch input:checked + .slider > .yes,
.contact-active-switch input:checked + .slider > .yes {
  display: inline;
  margin-left: -1em;
}

.control-buttons {
  padding: 1em;
}
.no-top-padding {
  padding-top: 0;
}
.cardListContainer .customerName,
.cardListContainer .card_id,
.cardListContainer .expiration {
  margin: auto auto auto 0;
}
.creditCardList {
  text-align: center;
  margin-bottom: 1em;
}

.current-menu,
.current-menu svg {
  fill: var(--navIconHover);
  color: var(--navIconHover);
}
.dashboardWidget {
  background-color: var(--container);
  border-radius: var(--border-radius);
  min-height: 15em;
  margin-bottom: 1em;
  padding: 1em 1.5em 1em 1em;
  border-top-width: 0.75em;
  border-top-style: solid;
  border-top-color: var(--defaultBorderColor);
}

.dashboardWidget hr {
  border-color: var(--defaultBorderColor);
}
.dashboardWidget ul {
  padding-left: 1.5em;
}

.plainLink {
  color: var(--highlight);
}
.dbwClock {
  font-size: var(--font-size-clock);
  text-align: center;
}
.dbwDate {
  font-size: var(--font-size-small);
  text-align: center;
}

.dialer {
  color: var(--text);
  text-decoration: none;
}
.disabledInput::placeholder {
  opacity: 0.5;
}
.justifySelfStart {
  justify-self: start;
}
.editcontact {
  justify-self: end;
  padding-left: 1em;
}
.employeeSelect {
  outline: none;
}
.oopsIcon {
  font-size: 2em;
}
.overlay.opaque {
  color: var(--text);
}
.overlayMessage,
.error {
  color: var(--error-text);
}
.overlayTitle {
  font-size: larger;
  font-weight: bold;
  color: var(--highlight);
}

.overlayTitle.dateFilterTitle {
  margin-bottom: 1em;
}

.dateFilterFilterType.filterReport {
  width: 100% !important;
}

.registrationError {
  margin: 2em;
  color: var(--highlight);
}

.errorBoundary {
  margin-top: 2em;
}
.errorBoundary > .errorPup {
  width: 25%;
}
.eyeball svg {
  fill: var(--text);
  width: 1.1em;
  height: auto;
}
.eyeball svg:hover {
  fill: var(--highlight);
}
.family {
  background-color: var(--body);
}
.filterReport,
.filter-button {
  position: relative;
  background-color: var(--body);
  border-radius: var(--border-radius);
  padding: 0.5em 2em;
  margin: 0 1em;
  border: 1px solid var(--defaultBackgroundBorderColor);
}
.filterReport {
  background-color: var(--input-background);
  padding: 0.3em 0.5em;
  margin: 0;
}
.filterReport svg {
  margin: auto;
}
.reportSettings #filter-list {
  width: 11em;
}
.recurringlist.invoices #filter-list {
  width: 9em;
}
.flexInputDiv {
  min-height: 2em;
}
.associatePicker svg,
.flexInputDiv svg {
  opacity: 0.15;
}
/* If an SVG is rendered inside an element with the "ghost" class inside an element with the "flexInput" class, 
then the opacity compounds and the SVG becomes too faint to see. So revert in these cases. */
.flexInputDiv .ghost svg {
  opacity: revert;
}
.associatePicker svg:hover,
.flexInputDiv svg:hover {
  opacity: 1;
}
.associatePicker .noOpacity svg {
  opacity: 1;
}
.folderContent {
  background-color: var(--input-background);
  width: 99%;
  min-height: 12em;
  overflow: hidden;
  border-radius: 0 var(--border-radius) var(--border-radius) var(--border-radius);
  color: var(--text);
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  padding: 0.5em;
}
.maastCardVaultTab,
.maastTransactionsTab,
.maastInvoicesTab,
.maastNotesTab,
.maastCustomFieldsTab,
.cartloomTab,
.linkedListItems,
.folderListItems,
.folderMatchListItems,
.folderAgreementItems,
.folderAuthorizationItems,
.folderMessageListItems,
.folderTextListItems,
.folderShippingItems,
.photoListItems {
  padding-top: 1em;
  padding-left: 1em;
  background-color: var(--input-background);
}
.folderMatchListItems {
  padding-bottom: 1em;
}

.vaultButton {
  align-self: start;
}

.reverbListItems {
  padding: 1em;
  background-color: var(--input-background);
}
.reverbPublish {
  width: max-content;
  margin-top: 1em;
}
.reverbLink {
  display: grid;
}
.reverbLink a {
  margin: auto auto auto 0;
  padding-top: 0.65em;
}

.reverbListItems .product-header {
  margin: auto auto auto 0;
}
.reverbDisclaimer {
  font-size: 80%;
  font-style: italic;
  opacity: 0.5;
  text-align: center;
  padding-top: 2em;
}
.importInstructions,
.reverbInstructions {
  padding: 1em;
}
.messageDateTime,
.settinginstructions {
  opacity: 0.95;
  font-size: 90%;
  margin-bottom: 1em;
}
.chrecaptchaElement {
  display: none;
}
.grecaptcha-badge {
  visibility: hidden !important;
}
.recaptchaDisclaimer {
  opacity: 0.6;
  font-size: 80%;
  margin-bottom: 1em;
}
.recaptchaDisclaimer a {
  color: var(--highlight);
}
.sendResult {
  font-size: 80%;
  margin-bottom: 1em;
  opacity: 0.5;
}
.sendFailure {
  color: var(--highlight);
  opacity: 1;
}
.easeIn {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.easeInActive {
  opacity: 1;
  transform: translateY(0);
}
.sms_card {
  background-color: var(--outgoing);
  padding: 1%;
  border-radius: var(--border-radius);
}
.sms_card.incoming,
.sms_card.outgoing {
  padding: 0.5%;
}
.sms_card.outgoing {
  color: var(--buttonText);
  display: inline-block;
}
.sms_card.incoming {
  color: var(--text-dark);
  background-color: var(--incoming);
  display: inline-block;
}
.sms_card_container {
  margin-bottom: 0.45em;
}
.sms_card_container.outgoing {
  padding-right: 40%;
}
.sms_card_container.incoming {
  text-align: right;
  padding-left: 40%;
}
.sms_card.outgoing .textDateTime {
  opacity: 0.4;
  font-size: 75%;
}
.sms_card.incoming .textDateTime {
  opacity: 0.75;
  font-size: 75%;
}

.getNewTexts {
  margin: auto;
}
.unread {
  font-weight: bold;
  color: var(--buttonText);
}
.verifyEmailinstructions {
  font-size: 80%;
  margin-bottom: 1em;
}
.noBottomMargin,
h2.settingsH3,
h3.settingsH3 {
  margin-bottom: 0;
  color: var(--headerText);
}
h2.settingsH3 {
  margin-top: 0;
}
h3.settingsH3 {
  margin-top: 1em;
}
.topMargin1em {
  margin-top: 1em !important;
}
.topMargin2em {
  margin-top: 2em !important;
}
.settingsTaxRateContainer {
  margin: 1em 0;
}
.settingsTaxRate {
  margin-right: 1em;
}

.fontSizeSmall {
  font-size: var(--font-size-small);
}

.topMarginSmall {
  margin-top: 0.5em;
}

.italic {
  font-style: italic;
}

.openFolder {
  min-height: 8.5em;
}

.openFolder.reverbListItems {
  min-height: 35.2em;
}
.openFolder.reverbListItems.noReverbKey {
  min-height: revert;
}

.folders {
  background-color: var(--body);
  padding: 0.5em;
  margin-right: 0.75em;
  width: 10em;
  text-align: center;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  color: var(--text);
  font-weight: bold;
  user-select: none;
  /**
  #282e38
  #303843
  */
  border-right: var(--tabBorder) 1px solid;
  border-top: var(--tabBorder) 1px solid;
  border-left: var(--tabBorder) 1px solid;
}
/* Customer view has too many tabs!!! */
.areaDetailCustomer .folders {
  width: 7.5em;
}
.folders {
  background-color: var(--tab);
}
.folders.selectedFolder {
  background-color: var(--container);
}
.gear-menu-option {
  margin-bottom: 0.75em;
}
.gear-menu-option a {
  text-decoration: none;
  color: var(--text);
}
.gear-menu-option:hover,
.gear-menu-option a:hover {
  color: var(--highlight);
}
.gear.header-icon {
  z-index: 2;
  flex-grow: 1;
  text-align: right;
}
.green-button {
  background-color: var(--green-button);
}
.green-button:hover {
  background-color: var(--green-button-hover);
}
.blue-button {
  background-color: var(--blue-button);
}
.blue-button:hover {
  background-color: var(--blue-button-hover);
}

.black-button {
  background-color: var(--input-background);
  color: var(--blackButtonText);
}
.black-button:hover {
  background-color: var(--input-background);
  color: var(--blackButtonText);
}

.headercustomfield {
  padding: 0.5em 0.5em 0.25em 0;
  font-weight: bold;
  background-color: var(--container);
  color: var(--headerText);
}

.header {
  padding: 0 0.5em 0.25em 0.5em;
  font-weight: bold;
  background-color: var(--container);
  color: var(--headerText);
}

.list-grid .header {
  padding: 0.25em 0.5em 0.25em 0.5em;
}

.buttonheader {
  padding: 0 0.5em 0.25em 0.5em;
  background-color: var(--container);
  color: var(--headerText);
}

.header span svg {
  margin-left: 0.5em;
}
.header-icon {
  color: var(--navIcon);
  font-size: var(--font-size-icon);
}
.header-icon svg {
  fill: var(--navIcon);
}
.headerlast {
  padding-bottom: 0.25em;
  font-weight: bold;
  background-color: var(--container);
  margin-right: 0;
  padding-right: 0.9em;
  color: var(--headerText);
}
.businessInfoContainer::placeholder,
.input-container::placeholder {
  color: var(--text);
}
.businessInfoContainer,
.input-container {
  background-color: var(--input-background);
  border-radius: var(--border-radius);
  width: 85%;
  margin: 0.5em auto;
  padding: 0 0.6em;
}
.businessInfoContainer {
  width: 90%;
}
.businessInfoContainer input,
input[type="date"],
input[type="datetime-local"],
.reportStartDate input,
.input-container input {
  background-color: var(--input-background);
  border-radius: var(--border-radius);
  color: var(--text);
  outline-width: 0;
  outline: none;
  border: none;
  width: 99%;
  margin: auto 0;
  padding: 0.25em 0.5em;
}

.reportStartDate input {
  border: 1pt solid var(--defaultBackgroundBorderColor);
}

.progressBar {
  margin: 1em;
}
.progressStep {
  padding: 0.1em;
  color: var(--headerText);
  font-weight: bold;
}

.progressStepActive {
  color: var(--highlight);
  font-weight: bold;
}
#creationdatetime {
  width: 67.5%;
}

#duedatetime {
  width: 60%;
}
.timesheetStartDate input,
.timesheetEndDate input {
  background-color: var(--search-background);
  border: 0.5px solid var(--defaultBackgroundBorderColor);
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

.inputfields {
  margin-bottom: 1em;
}
.inputfields label {
  display: block;
  text-align: center;
}

input.dirty {
  border-color: var(--border-color-dirty);
  border-width: 2px;
  border-style: solid;
}

.showMoreInvoices {
  margin: 1em 0;
}
.invoiceproductlist,
.returnproductlist,
.quoteproductlist,
.repairproductlist,
.orderproductlist,
.purchaseproductlist {
  margin-top: 1em;
}
.subscriptionListTotalsLabel,
.invoiceListTotalsLabel {
  font-weight: bold;
  text-align: right;
  margin-right: 1em;
}
.subscriptionListTotalsValue,
.invoiceListTotalsValue {
  text-align: right;
}

.list-header1 {
  padding-left: 0.5em;
}
.readonlyQuantity {
  padding-right: 0.5em;
}

.list-item {
  vertical-align: top;
  padding: 0.5em 0.3em 0.2em 0.12em;
}
.list-item.deleted {
  text-decoration: line-through;
  opacity: 0.5;
}
.timesheetlist .list-item {
  vertical-align: top;
  padding: 0 0.5em;
}

.listControlsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.register,
.login {
  margin: 1em 0;
  text-align: center;
  background-color: var(--background);
  color: var(--text);
  overflow: hidden;
}

.register svg,
.login svg {
  fill: var(--logoOutline);
}

.login h3 {
  text-align: center;
  padding-bottom: 0.25em;
  color: var(--text);
  font-size: var(--fontSizeHeadingSmall);
  font-weight: normal;
}

.registerTitles {
  font-size: var(--fontSizeHeadingSmall);
  text-align: center;
  color: var(--text);
  font-weight: normal;
  padding-bottom: 0.25em;
  margin: 0.5em 0;
}

.togglePlans {
  text-align: left;
  padding-bottom: 0.25em;
  color: var(--headerText);
  font-weight: normal;
}

.togglePlans:hover {
  color: var(--highlight);
}

.clientPageLoading,
.clientPage {
  margin: 2%;
  background-color: var(--body);
  color: var(--text);
  padding: 1em;
  border-radius: var(--border-radius);
}
.clientPageLoading {
  background-color: revert;
}
.storeName {
  font-size: var(--fontSizeHeading);
  font-family: Poppins-Regular, Arial, sans-serif;
  font-weight: bold;
}

.clientPage .logo img {
  height: 13em;
  margin-bottom: 2em;
}

.login-logo svg {
  height: 14.5em;
  margin-bottom: 2em;
}

.areaFAQContainer {
  margin: 1em;
  padding: 5%;
  background-color: var(--body);
  border-radius: var(--border-radius);
  color: var(--headerText);
}

.faqLogo {
  color: var(--headerText);
  margin-bottom: 1em;
}

.faqLogo svg {
  height: 5em;
  margin: auto;
}

.faqTitle,
.faqLogo svg {
  font-size: var(--fontSizeHeading);
}
.faqTitle,
.faqSearch {
  align-self: end;
  margin: auto;
}

.faqLogo .search-wrapper {
  display: inline-block;
}

.faqLogo .search-wrapper svg {
  height: 0.75em;
}

.faqQAContainer {
  background-color: var(--content-divider);
  margin: 1em;
  padding: 0.25em;
  border-radius: var(--border-radius);
  overflow: auto;
  max-width: 97%;
  width: 97%;
}

.faqContent {
  font-size: larger;
}

.faqQuestion,
.faqCategory {
  margin: 1em;
}
.faqCategory:hover {
  color: var(--highlight);
}

.faqAnswer {
  margin: 0 2em;
  color: var(--text);
  overflow: hidden;
}

.faqLogo svg .st1,
.errorPup .st1,
.login-logo svg .st1 {
  fill: var(--logo);
}

.faqLogo svg .st0,
.errorPup .st0,
.login-logo svg .st0 {
  fill: var(--logoOutline);
}

.faqMenuIcon svg {
  height: 1em;
  fill: var(--navIcon);
}

.alignSelfCenter {
  align-self: center;
}

.businessInfoWrapper,
.login-wrapper {
  border-radius: var(--border-radius);
  background-color: var(--body);
  padding: 0.5em 1em 1em 1em;
  max-width: 22em;
  text-align: center;
}
.instructions {
  margin-bottom: 1.5em;
  color: var(--text);
}

.gridCheckBox input {
  margin-bottom: 1em;
}
.choosePlanWrapper {
  border-radius: var(--border-radius);
  background-color: var(--body);
  padding: 0.5em 1em 1em 1em;
  max-width: 75%;
  text-align: left;
}
.login-container .instructions {
  margin-bottom: 1em;
}
.login-container .forgotPassword {
  margin-top: 1em;
  font-style: italic;
  cursor: pointer;
}
.login-container .forgotPassword:hover {
  color: var(--highlight);
}

.login-container .paymentForm {
  margin: 1em auto auto auto;
  text-align: center;
  padding-bottom: 0.5em;
}

.model,
.serial,
.description,
.technician {
  background-color: var(--input-background);
}
.nav-bar-container-dev,
.nav-bar-container {
  background-color: var(--background);
  align-items: end;
  padding: 1em;
  user-select: none;
}
.nav-bar-container-dev {
  background-color: var(--status-badge-paidrefund);
}
.nav-icon {
  text-align: center;
  padding: 1em;
  margin: auto 0 0 0;
  position: relative;
}
.nav-icon svg {
  width: 4em;
}
.nav-logo {
  width: 15em;
  padding: 0.5em 0 0 0;
}
.nav-logo h1 {
  white-space: nowrap;
}
.nav-svg {
  color: var(--navIcon);
  fill: var(--navIcon);
}
.saveProducts,
.downloadProducts,
.new-po {
  background-color: var(--green-button);
  color: var(--buttonText);
  padding: 0.4em 0;
  width: 15em;
  border-radius: var(--border-radius);
  display: inline-block;
  text-align: center;
  user-select: none;
}
.saveProducts,
.downloadProducts {
  margin: auto;
  width: 10.75em;
  margin-left: 1em;
}
.downloadProducts {
  background-color: var(--brown-button);
}
.redownloadProducts {
  width: 13em;
}
.new-po-wrapper {
  margin-top: 1em;
  text-align: center;
}
.noData {
  margin: 1em;
  font-style: italic;
}
.printedNotesTab,
.notesTab {
  padding: 1em;
  background-color: var(--input-background);
}
.notesDate {
  padding: 2px;
}
.printedNotesTab .notesValue {
  width: 100%;
}
.areaCustomFields input,
.printedNotesTab .notesValue,
.notesTab .notesValue {
  color: var(--text);
  background-color: var(--input-background);
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  overflow: hidden;
}
.areaCustomFields input {
  width: 100%;
}

.notesTab .notesValue.readonly {
  font-style: italic;
}
input.badEmail {
  border: 1px solid var(--mexican-red) !important;
}
.settinginstructions.badEmail {
  color: var(--highlight);
  opacity: 1;
}
.notesTab .notesValue.error {
  border: 0.5px solid var(--mexican-red);
}
.productlist .listControlsContainer,
.purchaselist .listControlsContainer,
.orderlist .listControlsContainer {
  justify-items: center;
}
.orderproductsearchresults .popup .popuplist {
  top: 6em;
  left: 1em;
}
.billingplanssearchresults .plan_type,
.billingplanssearchresults .plan_frequency,
.billingplanssearchresults .recur_amt,
.billingplanssearchresults .amt_tran {
  padding-top: 0;
  padding-bottom: 1em;
}
.billingplanssearchresults .plan_type {
  padding-left: 0.5em !important;
}
.paginationContainer {
  margin: 1em 3em;
  user-select: none;
}
.paidInFull {
  color: var(--text);
  text-align: center;
}
.pay-toggles {
  margin: 3em 0;
  width: 97%;
}
.partial-toggle {
  margin: 1em 2em 0 2em;
}
.partial-toggle .switch-wrapper {
  background-color: var(--body);
}
.payment-action-button {
  text-align: center;
  border-radius: var(--border-radius);
  width: 13em;
  height: 3.5em;
  user-select: none;
  cursor: pointer;
}
.payment-action-button-pp {
  padding: 0.4em;
  text-align: center;
  border-radius: var(--border-radius);
  width: 13em;
  height: 3.5em;
  user-select: none;
  cursor: pointer;
}
.payment-action-buttons .payment-action-button span {
  color: var(--buttonText);
  margin: auto;
  font-size: var(--font-size-larger);
  font-weight: bold;
}
.payment-action-buttons .payment-action-button svg {
  fill: var(--buttonText);
  height: 2em;
  margin: auto 0 auto auto;
  width: auto;
}
.payment-icon-container .action-buttons {
  margin-top: 2em;
}
.paymentMethods {
  font-size: var(--font-size-xsmall);
  margin: 8em auto auto auto;
}
.payment-action-button-wrapper {
  margin: 1em auto auto auto;
}
.declineMessage {
  margin-top: 1em;
}
.hoverPointer svg {
  fill: var(--silver);
  height: 1.05em;
}
.hoverPointer:hover svg {
  fill: var(--highlight);
}
.highlight.hoverPointer svg {
  fill: var(--highlight);
}
.hoverPointer:hover,
.selectTerminal:hover {
  color: var(--highlightHover);
  fill: var(--highlightHover);
  cursor: pointer;
}
.plus {
  padding: 0.11em 0.38em;
}

.areaCustomerSearchContainer .popuplist .list-item {
  word-wrap: break-word;
}
.areaProductManagerFields {
  margin-top: 1em;
}

.productManagerItem,
.product-item {
  margin-bottom: 0.5em;
}
.productPhoto {
  height: 100px;
}
.productPhoto.clientLogo {
  height: 200px;
}
.productPhoto.clientLogo {
  background-color: var(--defaultBorderColor);
  padding: 0.5em;
  border-radius: var(--border-radius);
}
.addphoto {
  height: 50px;
  margin: auto auto auto 0;
  opacity: 0.5;
}
.addphoto:hover {
  opacity: 1;
}
.addphoto svg {
  height: 50px;
}
.floatRight {
  float: right;
}
.productPhotoControls {
  display: flex;
  column-gap: 0.2em;
  float: right;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 7;
  opacity: 0.35;
}
.productPhotoControls:hover {
  opacity: 1;
}
.productPhotoEdit svg {
  height: 1.8em;
  color: var(--search-background);
  fill: var(--search-background);
  margin-top: 0.1em;
}
.product-toggles {
  margin-top: 1em;
}
.overlayError {
  color: var(--highlight);
}
.overlayError,
.overlayMessage {
  min-height: 1.5em;
}
.white {
  color: var(--headerText);
}
.headerText {
  color: var(--headerText);
}
.highlight {
  color: var(--highlight);
}
.highlight:hover {
  color: var(--highlightHover);
}
/* .orange {
  color: var(--highlight);
}
.orangehover:hover {
  color: var(--highlightHover);
} */
.underline {
  text-decoration: underline;
}
.subject {
  color: var(--headerText);
}
.red-button {
  background-color: var(--red-button);
}
.red-button:hover {
  background-color: var(--red-button-hover);
}
.saveIcon {
  fill: var(--green-button);
  color: var(--green-button);
}
.saveIcon:hover {
  fill: var(--green-button-hover);
  color: var(--green-button-hover);
}
.cancelIcon {
  fill: var(--red-button);
  color: var(--red-button);
}
.cancelIcon:hover {
  fill: var(--red-button-hover);
  color: var(--red-button-hover);
}
.remember-container .switch-wrapper {
  background-color: transparent;
}
.remember-container {
  width: 65%;
}
.removeCompanyContainer {
  text-align: right;
}
.flexPlaceholder,
.save-disabled,
.save-disabled::placeholder {
  opacity: 0.5;
}
.save-disabled:hover {
  cursor: not-allowed;
}
.span6_2 {
  grid-column: 6 / span 2;
}
.span3 {
  grid-column: 1 / span 3;
}
.span4 {
  grid-column: 1 / span 4;
}
.span5 {
  grid-column: 1 / span 5;
}
.span6 {
  grid-column: 1 / span 6;
}
.span7 {
  grid-column: 1 / span 7;
}
.span8 {
  grid-column: 1 / span 8;
}
.span9 {
  grid-column: 1 / span 9;
}
.span10 {
  grid-column: 1 / span 10;
}
.span11 {
  grid-column: 1 / span 11;
}
.span12 {
  grid-column: 1 / span 12;
}
.span14 {
  grid-column: 1 / span 14;
}
.specialdesc {
  font-size: var(--font-size-xsmall);
  padding-top: 0.5em;
}
.specialshortdesc {
  font-size: var(--font-size-large);
  padding-top: 0.5em;
}
.statusbadge {
  color: var(--buttonText);
  text-align: center;
  padding: 0 0.2em;
  border-radius: var(--border-radius);
}
.statusbadge.maastCustomer {
  margin: 0.5em 0;
}

.statusbadge.authapproved {
  background-color: var(--status-badge-authapproved);
}
.statusbadge.authcancelled {
  background-color: var(--status-badge-authcancelled);
}
.statusbadge.authdeclined {
  background-color: var(--status-badge-authdeclined);
}
.statusbadge.authfailed {
  background-color: var(--status-badge-authfailed);
}
.statusbadge.authnew {
  background-color: var(--status-badge-authnew);
}
.statusbadge.authnotattempted {
  background-color: var(--status-badge-authnotattempted);
}
.statusbadge.authpartialapproval {
  background-color: var(--status-badge-authpartialapproval);
}
.statusbadge.authpending {
  background-color: var(--status-badge-authpending);
}

.statusbadge.backordered {
  background-color: var(--status-badge-backordered);
}
.statusbadge.cancelled {
  background-color: var(--status-badge-cancelled);
}
.statusbadge.cancelledreturn {
  background-color: var(--status-badge-cancelled-return);
}
.statusbadge.contacted {
  background-color: var(--status-badge-contacted);
}
.statusbadge.diagnosed {
  background-color: var(--status-badge-diagnosed);
}
.statusbadge.archived {
  background-color: var(--status-badge-archived);
}
.statusbadge.draft {
  background-color: var(--status-badge-draft);
}
.statusbadge.published {
  background-color: var(--status-badge-published);
}
.statusbadge.family {
  background-color: var(--input-background);
  color: var(--text);
}
.statusbadge.fullyreceived {
  background-color: var(--status-badge-fullyreceived);
}
.statusbadge.invoiced {
  background-color: var(--status-badge-invoiced);
}
.statusbadge.onbench {
  background-color: var(--status-badge-onbench);
}
.statusbadge.onhold {
  background-color: var(--status-badge-onhold);
}
.statusbadge.open {
  background-color: var(--status-badge-open);
}
.statusbadge.ordered {
  background-color: var(--status-badge-ordered);
}
.statusbadge.paidinfull {
  background-color: var(--status-badge-paid-in-full);
}
.statusbadge.refunded {
  background-color: var(--status-badge-refunded);
}
.statusbadge.partiallyrefunded {
  background-color: var(--status-badge-partiallyrefunded);
}
.statusbadge.paidrefund {
  background-color: var(--status-badge-paidrefund);
}
.statusbadge.partialpayment {
  background-color: var(--status-badge-partialpayment);
}
.statusbadge.partiallyordered {
  background-color: var(--status-badge-partiallyordered);
}
.statusbadge.partiallyreceived {
  background-color: var(--status-badge-partiallyreceived);
}
.statusbadge.partsordered {
  background-color: var(--status-badge-partsordered);
}
.statusbadge.processed {
  background-color: var(--status-badge-processed);
}
.statusbadge.purchasecreated {
  background-color: var(--status-badge-processed);
}
.statusbadge.received {
  background-color: var(--status-badge-received);
}
.statusbadge.sent {
  background-color: var(--status-badge-sent);
}
.statusbadge.onlinepickedup {
  background-color: var(--status-badge-pickedup);
}
.statusbadge.onlineunshipped {
  background-color: var(--status-badge-unshipped);
}
.statusbadge.onlineshipped {
  background-color: var(--status-badge-shipped);
}
.statusbadge.onlinepartiallyshipped {
  background-color: var(--status-badge-partiallyshipped);
}
.statusbadge.maastCustomer {
  background-color: var(--status-badge-maast-customer);
}

.statusbadge.N,
.statusbadge.A,
.statusbadge.Active {
  background-color: var(--status-badge-sub-active);
}
.statusbadge.V,
.statusbadge.Vaulted {
  background-color: var(--status-badge-vaulted);
}
.statusbadge.S,
.statusbadge.Suspended {
  background-color: var(--status-badge-sub-suspended);
}
.statusbadge.C,
.statusbadge.Cancelled {
  background-color: var(--status-badge-sub-cancelled);
}
.statusbadge.P,
.statusbadge.Paused {
  background-color: var(--status-badge-sub-paused);
}
.statusbadge.D,
.statusbadge.Complete {
  background-color: var(--status-badge-sub-complete);
}
.statusbadge.New {
  background-color: var(--status-badge-new);
}
.statusbadge.Pending {
  background-color: var(--status-badge-pending);
}
.statusbadge.PreMatched {
  background-color: var(--status-badge-pre-matched);
}
.statusbadge.ProcessedCreated {
  background-color: var(--status-badge-processed-created);
}
.statusbadge.ProcessedMatched {
  background-color: var(--status-badge-processed-matched);
}
.balanceBadge.Active,
.balanceBadge.A {
  background-color: var(--status-badge-sub-active-light);
}
.balanceBadge.Suspended,
.balanceBadge.S {
  background-color: var(--status-badge-sub-suspended-light);
  color: var(--buttonText);
}
.balanceBadge.Complete,
.balanceBadge.D {
  background-color: var(--status-badge-sub-complete-light);
}
.balanceBadge.Paused,
.balanceBadge.P {
  background-color: var(--status-badge-sub-paused-light);
  color: var(--buttonText);
}
.balanceBadge.Cancelled,
.balanceBadge.C {
  background-color: var(--status-badge-sub-cancelled-light);
}

.storecredit,
.storedpayment,
.other {
  width: 100%;
}
.email_card {
  background-color: var(--background);
  padding: 1%;
  border-radius: var(--border-radius);
}

.supplier-card {
  margin-top: 1em;
}
.supplierwidget-header {
  color: var(--headerText);
  margin-top: 0.4em;
}
.supplierwidget-item input {
  color: var(--text);
  background-color: var(--input-background);
  width: 97%;
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  border-radius: var(--border-radius);
  padding: 0.25em 0;
}

.title {
  width: 10em;
  padding-top: 1em;
}
.registrationLogo svg {
  width: 3em;
  padding-top: 1em;
  padding-bottom: 1em;
}
.registrationTitle svg {
  width: 12em;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: 1em;
}
.toggle-label.disabledInput {
  opacity: 0.25;
}
.toprow {
  padding-bottom: 1em;
}
.total .balanceLabel,
.total .balanceValue {
  margin-top: 0.25em;
  font-weight: bold;
  font-size: var(--font-size-large);
}
.timesheetHeaderLabel,
.paymentTotalLabel,
.paymentTotalValue,
.totalTaxListLabel,
.totalTaxListValue,
.total .totalLabel,
.total .totalValue {
  margin: 1em 0 0.25em 0;
  font-weight: bold;
  font-size: var(--font-size-large);
}
.totalcontainer {
  margin-left: auto;
}

.totalPaymentsLabel,
.totalPaymentsValue,
.subTotalLabel,
.subTotalValue,
.taxSummaryLabel,
.taxSummaryValue,
.totalValue,
.totalLabel {
  display: inline;
}
.totalprice {
  margin: 3em 0;
}
.vendorimage {
  height: 8em;
  width: 8em;
}
#alerticonbadge circle {
  fill: var(--mexican-red);
}
#exp_date,
#card_number,
#cvv2 {
  height: 3em;
  width: 97%;
}
#filter-list {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: var(--text);
  background-color: var(--body);
  border: var(--defaultBorderColor) 0.05em solid;
  border-radius: var(--border-radius);
  padding: 0.6em 1em;
  z-index: 7;
}
#filter-list div {
  padding: 0.25em 1em;
}
.disposition #filter-list div {
  padding: 0.25em 0;
}
#filter-list div:hover {
  color: var(--highlight);
}
#filter-list.open {
  visibility: visible;
}
/* #gear {
  color: var(--text);
} */
#gear-container {
  text-align: right;
}
#gear-menu {
  visibility: visible;
  position: absolute;
  top: 3em;
  right: 0.5em;
  background-color: var(--input-background);
  border: var(--defaultBorderColor) 0.15em solid;
  border-radius: var(--border-radius);
  padding: 0.6em 1em;
  z-index: 7;
  opacity: 1;
  transition: opacity 0.25s;
}
#gear-menu::after {
  content: "";
  position: absolute;
  top: -0.65em;
  left: 88%;
  margin-left: -0.25em;
  border-width: 0.25em;
  border-style: solid;
  border-color: transparent transparent var(--defaultBorderColor) transparent;
}
#gear:hover {
  color: var(--navIconHover);
}
#list-container {
  margin: 0 1em;
  user-select: none;
}
.noSelect {
  user-select: none;
}
.list-grid {
  background-color: var(--body);
  padding: 1em;
  overflow: hidden;
}
.firstLeft {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
.lastRight {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.timesheetlist .list-grid {
  padding: 1em;
}
.productlist.products .list-grid,
.productlist.inventory .list-grid,
.productlist.summary .list-grid,
.quotelist .list-grid,
.orderlist .list-grid,
.repairlist .list-grid,
.reportlist .list-grid,
.purchaselist .list-grid,
.customerlist .list-grid,
.invoicelist .list-grid {
  margin-top: 0;
  border-radius: 0 var(--border-radius) var(--border-radius) var(--border-radius);
}
.settingslist .list-grid,
.supplierlist .list-grid,
.productlist .list-grid {
  margin-top: 1em;
  border-radius: var(--border-radius);
}
.productlist.products .list-grid,
.productlist.inventory .list-grid,
.productlist.summary .list-grid {
  margin-top: 0;
}
.inventoryMessage {
  margin: 5em auto;
  text-align: center;
}
.dispositionFilterItem {
  display: inline-block;
  width: 11em;
}
.statsContainer {
  margin: auto;
}
.inventoryStats span {
  margin: auto 1em;
  display: block;
}
.registrationFootnote {
  text-align: center;
}
.registrationSpecials {
  font-size: larger;
  text-align: center;
  margin: 2em;
  color: var(--highlight);
  font-weight: bold;
}
.registrationFootnote,
.footnote {
  vertical-align: super;
  font-size: var(--font-size-xsmall);
}
.footnoteText {
  font-size: var(--font-size-small);
  font-style: italic;
}

.dbwTitle {
  font-weight: bold;
  font-size: var(--font-size-large);
}
.dbwTitle .editWidget svg,
.superScript svg {
  font-size: var(--font-size-xxsmall);
  vertical-align: super;
  opacity: 0.35;
}
.dbwTitle .editWidget svg {
  margin-left: 0.5em;
  width: 2em;
  margin-right: 0.5em;
}

.dbwTitle .newsLogo svg {
  width: 2.5em;
  margin-right: 0.2em;
  transform: translateY(0.5em);
}
.registrationLogo svg .st0,
.dbwTitle svg .st0 {
  fill: var(--logoOutline);
}
.registrationLogo svg .st1,
.dbwTitle svg .st1 {
  fill: var(--logo);
}
.registrationTitle svg {
  fill: var(--registrationLogo);
}
.bold,
.unreadCountLabel {
  font-weight: bold;
}
.menuItemNotification {
  font-size: var(--font-size-xxsmall);
  vertical-align: super;
  float: right;
  color: var(--buttonText);
  background-color: var(--mexican-red);
  border-radius: var(--border-radius-round);
  width: 1.75em;
  height: 1.75em;
  margin-top: -0.5em;
  padding: 0.15em 0.15em 0.25em 0.15em;
  position: absolute;
}
#Customers .menuItemNotification {
  background-color: var(--blue-button);
}

.action-tab .menuItemNotification {
  margin-left: -1em;
}
.unreadCount {
  color: var(--highlight);
  font-size: var(--font-size-xsmall);
  vertical-align: super;
}
.dbwTitle:hover svg,
.superScript:hover svg {
  opacity: 1;
}
.copyToClipboard svg {
  opacity: 0.15;
}
.copyToClipboard:hover svg {
  opacity: 1;
}

.invoiceListTotalsGrid {
  font-size: var(--font-size-medium);
  margin: 1em;
  padding: 1em;
}
.panel_left {
  overflow: hidden;
}
.importFieldname {
  margin: auto 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#list-new-button {
  margin: auto 0;
}
#list-inventory-button {
  margin-bottom: 1em;
}
#list-inventory-button svg,
#list-new-button svg {
  color: var(--buttonText);
  fill: var(--buttonText);
  height: 0.75em;
  margin-right: 0.5em; /* margin-top: 0.25em; */
}
#login-button {
  margin: 1em auto auto auto;
  cursor: pointer;
  width: 60%;
  background-color: var(--green-button);
}
#login-button:hover {
  background-color: var(--green-button-hover);
}
.mobile-header {
  padding: 0 0.5em;
}
#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  cursor: pointer;
  touch-action: none;
}
#overlay.opaque {
  background-color: var(--container);
}
.overlay-buttons .action-button {
  margin: 0.5em;
}
.overlayDialog {
  background-color: var(--modal);
  border: var(--defaultBorderColor) 0.5px solid;
  border-radius: var(--border-radius);
  box-shadow: 0.25em 0.25em 0.15em rgba(29, 34, 43, 0.2);
  padding: 2% 1% 1% 1%;
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--text);
  transform: translate(-50%, -50%);
  width: 80%;
}
.overlayList select {
  border: solid 1.5px var(--search-background);
  background-color: var(--content-divider);
  color: var(--text);
  width: 18em;
  margin: auto;
}
.overlayMessage {
  margin: 1em auto auto auto;
  text-align: center;
  padding-bottom: 0.5em;
}
.overlayMessage svg,
.overlayMessage .amount svg,
.overlayMessage .gift_card_number svg {
  height: 2em;
}
.overlayMessage .gift_card_number svg {
  color: var(--white);
}
.overlayMessage .amount svg {
  color: var(--green-button);
}
.pageLeft,
.pageRight {
  margin: auto;
  width: 2em;
  text-align: center;
  font-size: var(--font-size-large);
}
.page-left:hover,
.page-right:hover {
  color: var(--highlight);
}
.pageNumber {
  color: var(--text);
  background-color: var(--search-background);
  width: 4em;
  box-shadow: none;
  border: solid 1px var(--body);
  text-align: center;
  outline-width: 0;
  outline: none;
}
.payment-action-button-pp svg {
  color: var(--buttonText);
  margin: auto;
  height: 2.3em;
  width: auto;
}
#product-view {
  border-radius: var(--border-radius);
}
#supplierwidget-container {
  background-color: var(--input-background);
  width: 97%;
}

.product-suppliers {
  padding: 0.5em;
}

.giftCardNumberInputContainer {
  width: 15em;
}

.areaAgreementDate {
  margin-top: 1em;
}
.areaCampaignCard {
  margin-bottom: 1em;
}

.additionalDataHeader {
  font-size: var(--font-size-large);
  font-weight: bold;
  margin-bottom: 0.25em;
}
.headerStyle {
  font-size: var(--font-size-large);
  font-weight: bold;
}

.headerStylewithMargin {
  margin-top: 1em;
  font-size: var(--font-size-large);
  font-weight: bold;
}

.areaDueDate,
.areaCreationDate,
.areaCampaignName,
.areaCampaignCard,
.areaAgreementDate,
.areaSalesperson {
  height: 2em;
}
/* removed #giftcardapply cause couldn't find an id with this value */
/* These ids were replaced with their classnames */
/* #tenderamount, #giftcardamount*/
.areaDueDate,
.areaOrderNumber,
.areaPONumber,
.areaSalesperson,
.areaStoreCredit,
.areaTrackingNumber,
.currency input,
.currency,
.giftcardamount,
.refundamount,
.repairadd,
.repairdelete,
.repairlabel,
.storecreditamount,
.tenderamount {
  text-align: right;
}

/*************Replace with class "center-align" *******************************/
.paymentheader {
  text-align: center;
  font-size: var(--fontSizeHeadingMedium);
  margin-top: 2em;
}

.reverbListItems .areaInputItem input {
  margin-top: auto;
}

.trackingInformation .areaInputItem textarea,
.shippingDimensions .areaInputItem input {
  background-color: var(--content-divider);
}
.shippingDimensions .areaInputItem input {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  padding: 0.25em 0.5em;
}

.trackingnbrcontainer textarea,
.product-item textarea,
.areaInputItem input {
  margin-top: 0.4em;
}
.list-item .areaInputItem input {
  padding: 0 0.5em;
  margin: auto;
}

.list-item.right-aligned .areaInputItem input {
  text-align: right;
}

.areaInputItem input::placeholder {
  font-family: Poppins-Regular, Arial, sans-serif;
}
.areaInputItem textarea {
  overflow: hidden;
}
.areaCampaignDetails.areaInputItem textarea {
  overflow: visible;
}
.areaSwitchesCampaign {
  padding: 2em 0;
}
.areaInputItem input {
  margin-top: 0;
}
.areaInputItem input,
.areaInputItem textarea {
  color: var(--text);
  background-color: var(--input-background);
  width: 97%;
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  border-radius: var(--border-radius);
  padding: 0.5em;
}
.areaCampaignDetails.areaInputItem textarea {
  width: 100%;
}
.areaCampaignDetails.areaInputItem label {
  color: var(--labelColor);
}
.securityText {
  margin-top: 1em;
}
/* Setting Page Style */
.recurringSubscriptionCard select,
.recurringSubscriptionCard input,
.subscriptionCard select,
.subscriptionCard input {
  width: 100%;
}

.settingCategory select,
.overlayDialog select,
.mapto select,
.planDescription input,
.duration input,
.startDate input,
.dateNext input,
.subscriptionName input,
.recurAmount input,
.paymentForm input,
.areaRecurringSubscriptions select,
.areaRecurringSubscriptionsNarrow select,
.areaSubscriptions select,
.areaSubscriptionsNarrow select,
.planInputItem input,
.planInputItem select,
.templateInputItem input,
.templateInputItem select,
.creditCardSettings input,
.thirdPartySettings input,
.settingsListGrid input,
.settingsListGrid select,
.settingsTable input,
.settingsTable select,
.settingInputItem select,
.settingInputItem input,
.customFields input,
.reverbListItems select {
  color: var(--text);
  background-color: var(--input-background);
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  border-radius: var(--border-radius);
  padding: 0.5em;
  margin-bottom: 0.5em;
}
/* Campaign styling */
.campaign input,
.campaign select {
  color: var(--text);
  background-color: var(--input-background);
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  border-radius: var(--border-radius);
  border-bottom: 1px solid var(--defaultBodyBorderColor);
  border-right: 1px solid var(--defaultBodyBorderColor);
  padding: 0.4em;
  margin: 0.5em;
}
.campaign textarea {
  border: 1px solid var(--defaultBodyBorderColor);
  outline: none;
  margin: 0.5em;
  padding: 0.5em;
  width: 97%;
}
.campaign input[type="date"],
.campaign input[type="time"] {
  width: 45%;
}
.campaign input[type="number"] {
  width: 25%;
}
.campaign label {
  color: var(--text);
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  border-radius: var(--border-radius);
  padding: 0.4em;
  margin: 0.5em;
}

.campaignTerms {
  color: var(--text);
}
/* ****************************** */
#filteron {
  margin-bottom: 0;
  width: 99%;
}

#filteron option {
  font-size: smaller;
}

.settingsTable input,
.settingsTable select {
  margin-right: 0.1em;
}
.settingsTable .switch-wrapper {
  margin-left: 1em;
}

.discountWrapper {
  padding: 0.3em;
  background-color: var(--toggle-wrapper);
  border-radius: var(--border-radius);
  display: grid;
  grid-template-columns: 1fr 3.5em;
}

.discountWrapper input {
  background-color: var(--body);
  color: var(--text);
  width: 97%;
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  border-radius: var(--border-radius);
  text-align: right;
}

.mapto select {
  min-width: 13em;
  margin-bottom: 0;
}

.dbwContent input,
.dbwContent select,
.dbwContent textarea,
.mapPaymentDescription input,
.mapPaymentType select {
  color: var(--text);
  background-color: var(--body);
  outline-width: 0;
  border: none;
  outline: none;
  resize: none;
  border-radius: var(--border-radius);
  padding: 0.5em;
  margin-bottom: 0.5em;
}
.dbwContent span,
.dbwContent input,
.dbwContent select {
  width: 97%;
  margin: 0.5em 0;
}
.nowrap,
.dbwNoWrap {
  white-space: nowrap;
  overflow: hidden;
}
.tagLabel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.reverbListItems select {
  background-color: var(--content-divider);
  min-width: 20em;
}

.trackingInformation .product-header,
.shippinglabel,
.shippingDimensions .product-header {
  margin: auto 0;
}

.trackingnbrlabel {
  margin-top: 0.75em;
}
.skippingPayment {
  text-decoration: line-through;
  opacity: 0.5;
}

/* Plans */

.planDuration input {
  margin-right: 1em;
}
.planInputItem.planDuration input {
  width: 50%;
}
.duration input {
  width: 9em;
}

.duration .statusbadge {
  margin: auto;
}

.planAmount input {
  width: 8.5em;
}

/* make width of labels the same so input will line up */
.areaPlanSettings .inputLabel {
  width: 25%;
  margin-bottom: 2em;
}
.planInputItem input {
  width: 65%;
}

.areaTemplateSettings,
.areaPlanSettings {
  margin-bottom: 2em;
}
/* templates ***************************************/
.templateInputItem .inputLabel {
  width: 24%;
  margin-bottom: 2em;
}

.templateInputItem .templateName,
.templateInputItem .templateAmount,
.templateInputItem .templateFrequency {
  width: 70%;
}

.templateInputItem .templateDuration {
  width: 50%;
}

/* templates ***************************************/

.infin {
  font-size: 1.7em;
}
.paymentForm input,
.paymentForm {
  width: 100%;
}

.areaRecurringSubscriptions select,
.areaRecurringSubscriptions input,
.areaSubscriptions select,
.areaSubscriptions input {
  width: 100%;
}

/* ************ */
/* The container */
.checkboxContainer {
  position: relative;
  padding-left: 1.5em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkboxContainer .checkmark {
  position: absolute;
  top: 0.2em;
  left: 0;
  height: 1em;
  width: 1em;
  background-color: var(--input-background);
  border-radius: var(--border-radius-small);
  border: solid 0.1em var(--checkboxBorderColor);
}

/* On mouse-over, add a grey background color */
.checkboxContainer :hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: var(--input-background);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkboxContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 0.4em;
  top: 0.2em;
  width: 0.2em;
  height: 0.5em;
  border: solid white;
  border-width: 0 0.1em 0.1em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* *********** */

.settingsTable select,
.reverbSettings {
  width: 99%;
}
.emailSettings input,
.generalSettings input {
  width: 100%;
}
.generalSettings .subdomainInput {
  width: 60%;
}

.emailSettings .switch-wrapper {
  background-color: transparent;
}

.displayInvoiceSettings .switch-wrapper,
.creditCardSettings .switch-wrapper {
  background-color: transparent;
  padding-left: 0;
  grid-template-columns: max-content max-content;
}
.displayInvoiceSettings .toggle-switch,
.creditCardSettings .toggle-switch {
  text-align: left;
}

.settingsListWrapper .switch-wrapper {
  width: max-content;
}
.displayInvoiceSettings .switch {
  width: 4em;
}
.creditCardSettings .switch {
  width: 8em;
}
.displayInvoiceSettings input:checked + .slider,
.creditCardSettings input:checked + .slider {
  background-color: var(--background);
}

.displayInvoiceSettings .slider:before,
.creditCardSettings .slider:before {
  transition: 0.4s;
}

.displayInvoiceSettings .slider,
.creditCardSettings .slider {
  background-color: var(--background);
}

.displayInvoiceSettings input:checked + .slider:before {
  transform: translateX(2em);
}
.creditCardSettings input:checked + .slider:before {
  transform: translateX(6em);
}

.displayInvoiceSettings .no {
  margin-left: -0.5em;
}
.creditCardSettings .no {
  margin-left: -3em;
}

.displayInvoiceSettings .mode .no,
.creditCardSettings .mode .no {
  margin-left: -2em;
}

.inputNumber {
  margin-left: 1em;
}
.deletesetting {
  margin-left: 1em;
}
.trash {
  padding-bottom: 1em;
}
.verifyEmail,
.addSetting {
  margin-bottom: 2em;
  width: max-content;
}
.verifyEmail {
  margin-top: 0.5em;
  margin-left: 0.25em;
}

.salesTaxValue {
  width: 7em;
}

.areaPlanSettings,
.creditCardSettings,
.displaySettings,
.generalSettings,
.emailSettings,
.giftCardSettings,
.customFieldSettings,
.repairSettings,
.salesTaxSettings,
.thirdPartySettings,
.usersSettings {
  padding: 1em;
  background-color: var(--content-divider);
  border-radius: var(--border-radius);
  margin-bottom: 1em;
  min-height: 32em;
}
.areaTemplateSettings {
  padding: 1em;
  background-color: var(--content-divider);
  border-radius: var(--border-radius);
  margin-bottom: 1em;
  min-height: 15em;
}

.usersSettings input.fullname {
  width: 100%;
}

.usersSettings input.email {
  width: 100%;
}

.inputLabel {
  width: 12em;
  display: inline-block;
  margin-right: 1em;
}

.creditCardSettings .inputLabel {
  width: 15em;
  margin-bottom: 0.5em;
}

/** Need consolidating AND a generic selector for these styles**/
.areaCloseButton svg:hover,
#clear-search svg:hover,
.caret:hover,
.plus:hover,
.edit:hover,
.editcontact:hover {
  color: var(--selectedHover);
  fill: var(--selectedHover);
}
.circle-button:hover {
  stroke: var(--selectedHover);
}
.header-icon svg:hover {
  fill: var(--selectedHover);
}
.header-icon:hover {
  color: var(--selectedHover);
}
.selected {
  color: var(--selected);
}
.selectedClientPageMenu {
  color: var(--navLinkHover);
  background-color: var(--blue-button);
  border-radius: var(--border-radius);
}
.selected:hover {
  color: var(--selectedHover);
}
.trackShipment:hover,
.trackShipment:hover svg,
.nav-icon:hover,
.nav-icon:hover svg {
  fill: var(--navIconHover);
  color: var(--navIconHover);
}
/* TODO: Remove this code to show bell */
.bell.nav-icon:hover,
.bell.nav-icon:hover svg {
  color: var(--navbar);
  fill: var(--navbar);
}
/* TODO: Remove this code to show bell */
.bell.dev.nav-icon:hover,
.bell.dev.nav-icon:hover svg {
  color: var(--status-badge-paidrefund);
  fill: var(--status-badge-paidrefund);
}
.areaSubscriptionStatus .statusbadge {
  margin-bottom: 1em;
}

.recurringSubscriptionCard .paymentSchedule {
  min-height: 6em;
}

.durationContainer label {
  margin-left: 1em;
}

/* .subscriptionLabel {
  padding: 0.5em;
  margin-bottom: 0.5em;
} */

.recurringSubscriptionCard,
.subscriptionCard {
  background-color: var(--content-divider);
  padding: 1em;
  margin-bottom: 1em;
  overflow: hidden;
}

.recurringSubscriptionCard label,
.subscriptionCard label {
  font-weight: bold;
}
.subscriptionCardHeader {
  text-align: right;
}
.areaStoredPaymentsHeader {
  margin-top: 2em;
  font-weight: bold;
}
.areaStoredPaymentsHeader span {
  margin: auto auto 0 0;
}
.reporttabletotal {
  font-weight: bold;
  color: var(--white);
}
.areaReportBody {
  margin-bottom: 1em;
}
.areaStoredPayments {
  margin-bottom: 2em;
  margin-top: 0.5em;
}
.overdueTotalAmount {
  font-weight: bold;
  color: var(--highlight);
  margin-bottom: 1em;
  font-size: var(--font-size-mobile-default);
  border: 1px solid var(--highlight);
  padding: 0.5em;
  text-align: center;
}
.metricListLabel.formula {
  font-family: monospace;
}

.userActions {
  margin: 3%;
  padding: 1%;
  width: 50%;
  background-color: var(--body);
  border-radius: var(--border-radius);
}
.userActions button {
  margin-top: 2%;
}

/*
Media Query for tablets
https://altamira.ai/blog/common-screen-sizes-for-responsive-web-design/
https://worship.agency/mobile-screen-sizes-for-2022-based-on-data-from-2021
*/
@media screen and (min-width: 450px) and (max-width: 1023px) {
  body {
    font-size: var(--font-size-tablet-default);
  }
}

/*Media Query for medium desktops*/
@media screen and (orientation: landscape) {
}

/*Media Query for desktops*/
@media screen and (min-width: 1024px) {
}
