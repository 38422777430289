@media screen and (min-width: 1024px) {
  body {
    margin: 0;
  }
  .applicationHeader {
    font-size: var(--font-size-desktop-header);
  }

  .info {
    color: var(--bannerInfoText);
    background-color: var(--bannerInfoBackground);
    text-align: center;
    font-weight: bold;
    padding: 0.1em;
  }

  .emergency {
    color: var(--bannerEmergencyText);
    background-color: var(--bannerEmergencyBackground);
    text-align: center;
    font-weight: bold;
    padding: 0.1em;
  }

  .usersSettings input.fullname {
    width: 15em;
  }

  .usersSettings input.email {
    width: 20em;
  }

  /* For webkit-based browsers */
  .textPanel::-webkit-scrollbar {
    width: 12px; /* Set the width of the scrollbar */
  }

  /* Track styles */
  .textPanel::-webkit-scrollbar-track {
    background-color: var(--body); /* Set the background color of the track */
  }
  .folderTextList .textPanel::-webkit-scrollbar-track {
    background-color: var(--input-background); /* Set the background color of the track */
  }

  /* Handle styles */
  .textPanel::-webkit-scrollbar-thumb {
    background-color: var(--status-badge-diagnosed); /* Set the color of the handle */
    border-radius: 6px; /* Set the border radius of the handle */
  }

  /* Handle on hover */
  .textPanel::-webkit-scrollbar-thumb:hover {
    background-color: var(--status-badge-backordered); /* Change the color on hover */
  }

  .app-body {
    font-size: var(--font-size-desktop-default);
  }
  .creation-person {
    margin-bottom: 1em;
  }
  .header-container {
    padding: 0;
    margin: 0;
  }
  .tagListParent .tagElementContainer,
  .tooltip .tagElementContainer,
  .tooltip .tagContainer {
    flex-wrap: nowrap;
  }
  .registerTitles {
    font-size: var(--fontSizeHeading);
  }

  .togglePlans {
    margin-left: 32%;
    font-size: var(--fontSizeHeadingSmall);
  }
  .suggestionsParent {
    position: relative;
  }
  .suggestions.hidden {
    opacity: 0;
  }
  .suggestions {
    position: absolute;
    z-index: 1;
    bottom: 0em;
    left: 0;
    width: 97%;
    background-color: var(--search-background);
    border: 1px solid var(--body);
    border-radius: var(--border-radius);
    max-height: 10em;
    overflow-y: auto;
    transition: opacity 0.3s;
    opacity: 1;
    transform: translateY(100%);
  }
  ul.suggestions {
    padding: 0.5em;
    margin: 0;
  }
  .suggestions li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .suggestionActive {
    color: var(--text-dark);
    background-color: var(--body);
  }
  .newTooltip.tagListParent {
    position: relative;
    display: inline-block;
  }
  .newTooltip.tagListParent .newTooltipHoverText {
    position: absolute;
    visibility: hidden;
    width: 100%;
    background-color: transparent;
    border: none;
    color: revert;
    border-radius: revert;
    z-index: 1;
    top: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s;
    transform: translateX(125%);
  }
  .newTooltip:hover .newTooltipHoverText {
    visibility: visible;
    opacity: 1;
  }
  .newTooltip.tagListParent .newTooltipHoverText {
    border: none;
    background-color: transparent;
  }

  .areaProspectAdditionalData {
    background-color: var(--container);
    border-radius: var(--border-radius);
    border-right: 1.5px solid var(--body);
    border-bottom: 1.5px solid var(--body);
    padding: 1em;
  }
  .additionalDataHeader {
    font-size: var(--font-size-large);
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.25em;
  }

  .additionalDataKey {
    font-weight: bold;
  }

  .areaAgreementDate {
    margin-top: 0;
  }

  .areaCampaignCard {
    margin-bottom: 0;
  }

  .ghost {
    opacity: 0.4;
  }
  .ghost:hover {
    opacity: 1;
  }

  .action-tab {
    min-width: 7em;
    padding: 0.5em;
  }

  .action-tab-balance {
    width: 100%; /*Need this width to make orders and purchase match invoices, repairs, quotes*/
  }

  .action-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: var(--font-size-desktop-button);
  }
  .importDetails .action-buttons {
    justify-content: center;
    column-gap: 1em;
  }
  .action-buttons .action-button-cell {
    margin-right: 1em;
  }
  .action-buttons .center-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
  }
  .action-icons {
    padding-top: 0;
    padding-left: 0;
  }
  .totalTaxListLabel,
  .paymentTotalLabel,
  .taxListLabel,
  .metricListLabel {
    margin-left: 1em;
  }
  .paymentheader {
    font-size: revert;
    margin-top: revert;
  }
  #exp_date,
  #card_number,
  #cvv2 {
    width: 100%;
  }
  .pay-toggles {
    width: 100%;
  }

  .addr1 {
    padding-top: 0;
  }
  .textlist .list-grid {
    min-height: 35em;
  }
  .textPanel {
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 17px;
    margin-bottom: 1em;
  }
  .textlist .textPanel {
    height: 29em;
  }
  .folderTextList .textPanel {
    height: 20em;
  }
  .textPanel > :first-child {
    margin-bottom: auto !important;
  }
  .textlist .panel_left,
  .folderTextList .panel_left {
    border-radius: var(--border-radius);
  }
  .textlist .panel_left {
    background-color: var(--background);
  }
  .folderTextList .panel_left {
    margin-bottom: 1em;
    background-color: var(--list-items-container);
  }

  .emailSettings input {
    width: 35%;
  }

  .campaign input[type="date"],
  .campaign input[type="time"] {
    width: 12%;
  }
  .campaign input[type="number"] {
    width: 8%;
  }

  .settingsTable input,
  .settingsTable select {
    margin-right: 1em;
  }

  .partial-toggle {
    margin: 1em 6.5em 0 6.5em;
  }

  .creditCardSettings .switch-wrapper {
    background-color: transparent;
    padding-left: 0;
    grid-template-columns: 15em max-content;
  }

  .productManagerItem label,
  .productManagerItem input {
    margin: auto 0;
    font-size: var(--font-size-small);
    font-weight: normal;
    color: var(--text);
  }
  .productManagerItem input {
    background-color: var(--body);
    padding: 0.15em 0 0.15em 0.5em;
  }

  .productManagerItem {
    background-color: var(--toggle-wrapper);
    padding: 0.25em 0.5em;
    border-radius: var(--border-radius);
  }

  .areaBaseDetailContainer .areaSubscribeContainer,
  .areaBaseDetailContainer .areaFoldersContainer,
  .areaBaseDetailContainer .areaSwitchesProduct,
  .areaBaseDetailContainer .areaSwitchesSupplier,
  .areaBaseDetailContainer .areaSwitchesCustomer,
  .areaBaseDetailContainer .areaIdPanel .areaCreationDate,
  .areaBaseDetailContainer .areaIdPanel .areaAgreementDate,
  .areaBaseDetailContainer .areaIdPanel .areaDueDate,
  .areaBaseDetailContainer .areaIdPanel .areaSalesperson,
  .areaBaseDetailContainer .areaIdPanel .areaStoreCredit,
  .areaBaseDetailContainer .areaIdPanel .areaPONumber,
  .areaBaseDetailContainer .areaIdPanel .areaTrackingNumber,
  .areaBaseDetailContainer .areaIdPanel .areaOrderNumber,
  .areaBaseDetailContainer.areaDetailCustomer .areaProductCard {
    display: block;
  }

  .monitoringWrapper,
  .areaBaseDetailContainer {
    margin: 2em;
    padding: 2em;
    min-height: 25em;
  }
  .areaFAQContainer {
    margin: 3em;
    padding: 3em;
    font-size: x-large;
  }

  .faqTitle,
  .faqSearch {
    margin: 0;
    text-align: left;
  }

  .areaBaseDetailContainer.areaDetailReport {
    min-height: 0;
  }
  .areaButtonControlsContainer {
    margin: auto;
  }
  .areaProspectCard,
  .areaContactCardContainer {
    margin: 0 0 auto 0;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    z-index: 10;
    gap: 0.5em;
    min-height: 12em;
  }
  /* .areaDetailPay .totalcontainer .totalLabels {margin-left: 75%;} */

  .areaRepairCardDeck {
    margin: 0 auto;
    width: 100%;
  }
  .areaRepairItemCard input {
    margin: 0.25em;
  }
  .areaRepairItemInputContainer.model input,
  .areaRepairItemInputContainer.model label,
  .areaRepairItemInputContainer.technician input,
  .areaRepairItemInputContainer.technician label,
  .areaRepairItemInputContainer.family input,
  .areaRepairItemInputContainer.serial input,
  .areaRepairItemInputContainer.serial label {
    width: 100%;
    margin: auto;
  }
  .areaPlanSettings .planDuration .inputLabel,
  .areaPlanSettings .inputLabel {
    width: 20%;
    margin-bottom: 2em;
  }
  .areaReportHeader {
    margin-top: 1em;
  }
  .areaCloseButton {
    margin-bottom: 1.25em; /* 6/11/2023 */
  }
  .tag {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 0.5em;
    max-height: 1.7em;
  }
  .tagText {
    min-width: auto;
  }
  .tag.editing {
    max-height: 3.5em;
    font-size: revert;
  }
  .tagList input[type="color"] {
    height: revert;
  }

  .areaStoreCredit {
    color: var(--highlight);
    font-weight: bold;
    margin: 1em 0 0 0;
  }

  .areaSwitchesProduct {
    font-size: var(--font-size-small);
  }
  .areaTrackingNumber,
  .areaPONumber {
    margin: 1em 0 1em auto;
  }
  .component-title #companyname {
    width: 50%;
    padding-left: 0.5em;
  }
  .contact-card {
    display: flex;
    width: auto;
    border-right: 1.5px solid var(--body);
    border-bottom: 1.5px solid var(--body);
  }

  .control-buttons {
    background-color: transparent !important;
  }
  .creditcardfields,
  .giftcardfields,
  .checkfields,
  .paypalfields,
  .otherfields {
    margin-bottom: 1em;
  }

  .usersSettings {
    grid-column: 1 / span 2;
  }

  .apikey input,
  .authToken input {
    width: 55%;
  }

  .customer-contacts {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
  }
  .supplierlist .list-grid,
  .customerlist .list-grid {
    overflow: visible;
  }

  .invoiceListTotalsGrid {
    font-size: var(--font-size-medium);
    margin: 1em 3em;
    padding: 1em 4em 1em 1em;
  }
  .filter-button {
    position: relative;
    background-color: var(--body);
    border-radius: var(--border-radius);
    padding: 0.5em 2em;
    margin: 0 1em;
  }
  .disposition .filter-button {
    padding: 0.5em 1em;
  }
  .filterReport {
    background-color: var(--input-background);
    position: relative;
    border-radius: var(--border-radius);
    width: 11em;
  }

  #filteron option {
    font-size: revert;
  }

  .dateFilterType,
  .reportFilterType,
  .filter-by {
    margin: auto 0 auto auto;
  }

  .register,
  .login-logo svg {
    margin-bottom: 2em;
  }
  .nav-bar-container-dev,
  .nav-bar-container {
    margin: 0;
    background-color: var(--navbar);
    padding: 0.5em 1em;
  }
  .nav-bar-container-dev {
    background-color: var(--status-badge-paidrefund);
  }
  .nav-icon {
    padding: 0;
  }
  .nav-icon svg {
    width: 1.8em;
  }
  .nav-label {
    font-size: var(--font-size-xxsmall);
  }
  .nav-client {
    font-size: var(--font-size-xxxsmall);
  }
  .nav-svg.customer {
    margin-bottom: 0.25em;
  }
  .nav-svg.dashboard {
    margin-bottom: 0.1em;
  }
  .nav-svg.invoice {
    margin-bottom: 0.3em;
  }
  .nav-svg.message {
    margin-bottom: 0.4em;
  }
  .nav-svg.order {
    margin-bottom: 0.3em;
    transform: scale(1.1);
  }
  .nav-svg.product {
    margin-bottom: 0.35em;
    transform: scale(1.2);
  }
  .nav-svg.purchase {
    margin-bottom: 0.15em;
  }
  .nav-svg.quote {
    transform: scale(0.85);
  }
  .nav-svg.repair {
    margin-bottom: 0.2em;
  }
  .nav-svg.report {
    transform: scale(0.8);
    margin-bottom: 0.15em;
  }
  .nav-svg.supplier {
    margin-bottom: 0.35em;
    transform: scale(1.1);
  }
  .nav-svg.timesheet {
    transform: scale(0.8);
    margin-bottom: 0.1em;
  }
  .nav-svg.recurrings,
  .nav-svg.billing {
    transform: scale(0.8);
    margin-bottom: 0.1em;
  }
  .saveProducts,
  .downloadProducts,
  .new-po {
    padding: 0.5em;
  }
  .payment-cash,
  .payment-giftcard {
    margin: 0 15em;
  }
  .payment-credit {
    margin: 0 8em;
  }
  .paymentMethods {
    font-size: var(--font-size-medium);
  }

  .paymentForm {
    width: 20vw;
  }
  .businessInfoWrapper {
    max-width: 33%;
  }
  .progressBar {
    font-size: var(--fontSizeHeadingSmall);
  }
  .progressStep {
    padding: 1em;
  }
  .popup .popuplist {
    padding: 1em;
  }
  .popup .popuplist::after {
    left: 7%;
  }

  .popup .popuplist .list-item {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  /* .areaInvoices .list-item {
    margin: auto;
  } */
  .list-item {
    padding: 0.5em 0.5em 0.2em 0.5em;
  }

  .subscriptionLabel {
    padding: 0.5em;
    margin-bottom: 0.5em;
  }

  .planDescription {
    white-space: nowrap;
    overflow: hidden;
  }

  .areaSubscriptions select,
  .areaSubscriptions input {
    width: 100%;
  }
  .payment select,
  .planDescription input {
    width: 100%;
  }

  .skipOxtPayment {
    margin-left: 1em;
  }
  .durationContainer label input,
  .skipOxtPayment input {
    width: auto;
  }

  .recurringlist.cron .listControlsContainer {
    min-height: 2.6em;
  }

  .recurringSubscriptionCard select,
  .recurringSubscriptionCard input {
    margin-top: 0;
    width: 44%;
  }

  .recurringSubscriptionCard .subscriptionName input,
  .subscriptionCardItem.planDescription input {
    width: 100%;
  }
  .subscriptionCardItem.dayOfCycle select.annualDayOfCycle {
    width: auto;
  }

  .subscriptionCardItem.paymentMethod select {
    width: 70%;
  }

  .customLabel {
    margin: auto 0 auto 0;
  }

  .areaSubscriptions .list-item {
    background-color: var(--content-divider);
  }

  .areaRecurringSubscriptions .list-item {
    background-color: var(--content-divider);
  }

  .billingplanssearchresults .plan_type,
  .billingplanssearchresults .plan_frequency,
  .billingplanssearchresults .recur_amt,
  .billingplanssearchresults .amt_tran {
    padding-top: 0.5em;
  }

  .searchResultIcon svg {
    color: var(--text);
    fill: var(--text);
    margin-right: 0.5em;
    width: 1em;
  }

  .areaDetailBilling .areaContactHeader {
    font-size: var(--fontSizeHeading);
  }

  .timesheetlist .list-item {
    padding: 0 0.75em;
  }
  .orderpaymentlist .list-item {
    padding: 0.5em 0;
  }

  .actionButtonStockOrder {
    width: 10em;
  }

  .postalcode {
    margin-right: 10em;
  }
  .product-details {
    background-color: var(--content-divider);
    padding: 2%;
    border-radius: var(--border-radius);
  }
  .product-header {
    font-weight: bold;
    color: var(--headerText);
    margin-bottom: 1em;
  }
  .product-item.item-storesku {
    width: 100%;
    margin: 0;
  }

  .areaAgreementDate,
  .areaCreationDate,
  .areaCampaignLink,
  .areaCampaignName,
  .areaCampaignCard,
  .amtcharge {
    text-align: right;
  }
  .repairlabel {
    text-align: left;
  }
  .salesperson {
    margin-bottom: 1em;
  }
  .statusbadge {
    width: 10em;
  }
  .areaSubscriptions .statusbadge {
    width: auto;
  }

  .areaSubscriptionStatus .statusbadge {
    display: grid;
    width: auto;
  }

  .areaRecurringSubscriptions .statusbadge {
    width: auto;
  }

  .areaSubscriptionStatus .statusbadge {
    display: grid;
    width: auto;
  }

  .subscriptionCardItem.subscriptionStatus {
    width: 50%;
  }

  .areaStoredPaymentsHeader {
    margin-top: 0;
  }

  .overdueTotalAmount {
    font-size: var(--font-size-large);
  }
  .areaSubscriptionStatus span {
    margin: auto;
  }

  .areaSubscriptions .list-item {
    margin-top: auto;
    margin-bottom: auto;
  }
  .areaSubscriptionStatus span {
    margin: auto;
  }

  .areaRecurringSubscriptions .list-item {
    margin-top: auto;
    margin-bottom: auto;
  }

  .subTotalLabel,
  .subTotalValue,
  .taxSummaryLabel,
  .taxSummaryValue,
  .totalValue,
  .totalLabel {
    display: inline;
  }
  .supplier-card {
    margin-top: 0;
  }
  .supplierwidget-header {
    font-weight: bold;
    color: var(--headerText);
  }
  .totalprice {
    margin-top: 3em;
  }
  .addWidget {
    position: absolute;
    top: 1em;
    right: 1em;
  }
  .dashboardWidget {
    width: 15.67%;
  }
  .dashboardWidget.doubleWidth {
    width: 31.34%;
  }
  .dbwDemo div {
    margin-bottom: 0.75em;
  }
  .dbwDemo,
  .dbwNews {
    width: 31.3%;
    max-height: 15em;
  }
  .dbwPlan {
    width: 45%;
  }
  .dbwPlan div {
    margin-bottom: 1em;
  }
  .bell {
    margin: auto;
    align-self: center;
    display: flex;
    color: var(--navbar);
  }
  .bell.dev {
    margin: auto;
    align-self: center;
    display: flex;
    color: var(--status-badge-paidrefund);
  }
  .bell svg,
  #gear svg {
    height: 1.5em;
    margin-bottom: 0.1em;
    align-self: center;
  }
  #gear-menu::after {
    left: 95%;
  }
  #gear-container {
    font-size: 1.1vw;
  }
  #gear-menu {
    top: 5vw;
    right: 0.5em;
  }
  #list-container {
    margin: 1em 3em;
  }
  #list-inventory-button,
  #list-new-button {
    margin: auto;
  }
  .overlayDialog {
    height: auto;
    width: auto;
    font-size: var(--font-size-desktop-default);
    padding: 1% 1% 1% 1%;
  }
  .overlayList select {
    width: auto;
    min-width: 20em;
  }
  .reportButton {
    margin: auto auto 0.25em auto;
    display: inline-block;
  }

  .metricReportHeaders,
  .ponumber {
    color: var(--headerText);
  }
  #search {
    display: flex;
    margin: auto;
  }
  #supplierwidget-container {
    background-color: var(--container);
    width: 99%;
  }
  .displayInline {
    display: inline;
  }

  /* 
  #CustomerList {margin-left: -0.25em;}
  #InvoiceList {margin-left: 0.3em;}
  #InvoiceList {margin-left: 0.4em;}
  #PurchaseList {margin-left: -0.25em;}
  #RepairList {margin-left: 0.3em;}
  #ReportList {margin-left: -0.3em;}
  #TimesheetList {margin-left: -0.3em;}
  #MessageList {margin-left: -0.3em;} 
  */
}
